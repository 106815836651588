h1 {
  text-align: center;
  font-size: 2em !important;
  font-family: Roboto Mono,Roboto,system-ui,BlinkMacSystemFont,-apple-system,Segoe UI,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}
body {
  margin: 0 0 20px 0;
  padding: 0;
  font-family: "Enriqueta", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-size: 1.1em !important;
}

.align-self-center {
  margin: 0 auto !important;
}
.btn {
  font-size: 1em;
  font-family: Roboto Mono,Roboto,system-ui,BlinkMacSystemFont,-apple-system,Segoe UI,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

